import React from "react";

import SessionContainer from "@/components/chat/sessionContainer";
import { isBrowser } from "@/utils/env";

const VideoSessionPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return <SessionContainer />;
};

export default VideoSessionPage;
